import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { getBillingPortal } from '../requests';
import { BillingPortalError } from '../components/Errors';

function Billing() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getBillingPortal()
      .then((res) => {
        window.location.href = res.url;
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  return (
    <Box sx={{ width: '100%', marginTop: 10 }}>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <BillingPortalError />
      )}
    </Box>
  );
}

export default Billing;
