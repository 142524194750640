// eslint-disable-next-line import/prefer-default-export
import { Auth } from '@aws-amplify/auth';
import { Analytics } from '@aws-amplify/analytics';
import config from './config';

Auth.configure({
  aws_cognito_region: config.aws['AWS-REGION'],
  aws_user_pools_id: config.aws['COGNITO-USER-POOL'],
  aws_user_pools_web_client_id: config.aws['COGNITO-USER-POOL-CLIENT'],
  aws_cognito_identity_pool_id: config.aws['COGNITO-IDENTITY-POOL'],
});

Analytics.configure({
  AWSPinpoint: {
    appId: config.aws['PINPOINT-APP'],
    region: config.aws['AWS-REGION'],
  },
  disabled: false,
  autoSessionRecord: true,
});

Analytics.autoTrack('session', { enable: true });
Analytics.autoTrack('pageView', { enable: true, type: 'SPA' });
Analytics.autoTrack('event', {
  enable: true,
  events: ['click'],
  selectorPrefix: 'data-amplify-analytics-',
});
