import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import './styles/app.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import { Analytics } from '@aws-amplify/analytics';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { ErrorBoundary } from 'react-error-boundary';
import { PageNotFoundError } from './components/Errors';
import {
  LoginPage,
  RegisterPage,
  ForgottonPasswordPage,
} from './pages/Authentication';
import Dashboard from './pages/Dashboard';
import Search from './pages/Search';
import Account from './pages/Account';
import Billing from './pages/Billing';
import CheckoutConfirm from './pages/CheckoutConfirm';
import MLFooter from './components/Footer';
import MLAppbar from './components/Appbar';
import Viewer from './pages/Viewer';
import theme from './configs/theme';
import ErrorFallback from './components/ErrorFallback';
import './configs/aws';

function App() {
  const [isAuthenticated, setLoggedIn] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      let user = null;
      try {
        user = await Auth.currentAuthenticatedUser();
        if (user) {
          setLoggedIn(true);
          setCurrentUser(user);
          Analytics.record({
            name: 'UserAuthenticated',
            attributes: { ...user.attributes },
          });
          Analytics.updateEndpoint({
            userId: user.attributes.sub,
            userAttributes: {
              sub: [user.attributes.sub],
              email: [user.attributes.email],
              name: [user.attributes.name],
            },

          }).then(() => {});
        } else {
          setLoggedIn(false);
        }
      } catch (e) {
        setLoggedIn(false);
      } finally {
        setLoading(false);
      }
    })();
    console.log(
      'Oh Hi, What you looking for?',
      'Found a bug? Let us know support@moonlitmedia.co.uk',
    );
  }, []);

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress color="primary" />
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ThemeProvider theme={theme}>
        <Box sx={{ minHeight: '91vh', overflowX: 'hidden' }}>
          <MLAppbar isAuthenticated={isAuthenticated} />
          <Router>
            {isAuthenticated ? (
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/search" element={<Search />} />
                <Route path="/logout" />
                <Route path="/account" element={<Account />} />
                <Route path="/billing" element={<Billing />} />
                <Route path="/checkout-success" element={<CheckoutConfirm />} />
                <Route path="/viewer" element={<Viewer />} />
                <Route
                  path="/change-password"
                  element={<ForgottonPasswordPage title="Change Password" email={currentUser.attributes.email} />}
                />
                <Route path="*" element={<PageNotFoundError />} />
              </Routes>
            ) : (
              <Routes>
                <Route path="/register" element={<RegisterPage />} />
                <Route
                  path="/forgot-password"
                  element={<ForgottonPasswordPage />}
                />
                <Route path="*" element={<LoginPage />} />
              </Routes>
            )}
          </Router>
        </Box>
        <MLFooter />
      </ThemeProvider>
    </ErrorBoundary>
  );
}

// eslint-disable-next-line max-len
// NTk2Zjc1MjA2NjZmNzU2ZTY0MjA3NDY4NjUyMDczNjU2MzcyNjU3NDIwNmQ2NTczNzM2MTY3NjUyZTIwNDU2ZDYxNjk2YzIwNzE2ZTZkNjg2NzczNmI2ODYxNzk0MDZkNmY2ZjZlNmM2OTc0NmQ2NTY0Njk2MTJlNjM2ZjJlNzU2YjIwNjY2ZjcyMjA3OTZmNzU3MjIwNzA3MjY5N2E2NTJl
export default App;
