import { Button, Typography, Box } from '@mui/material';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import useMediaQuery from '@mui/material/useMediaQuery';

const defaults = {
  heading: 'Unexpected Error',
  message: 'Unknown error contact support@moonlitmedia.co.uk',
};

function Error({
  heading = defaults.heading,
  message = defaults.message,
  image,
  children,
}) {
  const isMobileWidth = useMediaQuery('(max-width:600px)');

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Card
        sx={{
          maxWidth: 500,
          boxShadow: 'none',
        }}
      >
        <CardMedia
          component="img"
          sx={{ width: isMobileWidth ? '100%' : 500 }}
          image={image || `/images/dog${Math.floor(Math.random() * 5) + 1}.jpg`}
          alt="Error img"
        />
        <CardContent>
          <Typography align="center" variant="h4">
            {heading}
          </Typography>
          <Typography align="center" variant="subtitle1">
            {message}
          </Typography>
          {children}
        </CardContent>
      </Card>
    </Box>
  );
}

export function NoResultsError({ clearButton = false }) {
  const [, setSearchParams] = useSearchParams();

  return (
    <Error
      heading="No Results"
      message={"Sorry, we couldn't find any results for that criteria"}
      image="/images/ErrorImage.png"
    >
      {clearButton && (
        <Button
          variant="contained"
          sx={{ marginTop: 2 }}
          onClick={() => setSearchParams({})}
        >
          Clear filter
        </Button>
      )}
    </Error>
  );
}

export function NotSubscribedError({ url }) {
  return (
    <Error
      heading="Subscribe Now"
      message={
        'Subscribe today and gain access to templates, e-books, content ideas and more to level up your social media content. Plus gain access to our exclusive facebook group with live trainings and more. \nUnlimited access for one monthly price. Cancel anytime.'
      }
      image="/images/subscribe.png"

    >
      <Box textAlign="center">
        <Button
          variant="contained"
          sx={{ marginTop: 2, width: 'auto' }}
          componet="a"
          href={url}
        >
          Subscribe to Moonlit Magic
        </Button>
      </Box>
    </Error>
  );
}

export function FailedToLoadContent() {
  return (
    <Error
      heading={"Couldn't load content"}
      message={"Sorry, we can't load any content at the moment"}
    />
  );
}

export function PageNotFoundError() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="80vh"
    >
      <Error
        heading="Page Not Found"
        message={"Sorry, we couldn't find the page you requested"}
        link={{}}
      >
        <Box textAlign="center">
          <Button
            variant="contained"
            sx={{ marginTop: 2, width: 'auto' }}
            componet="a"
            href="/"
          >
            Back to Dashboard
          </Button>
        </Box>
      </Error>
    </Box>
  );
}

export function BillingPortalError() {
  return (
    <Error
      heading={"We couldn't find your billing information"}
      message="Already Subscribed? Contact support@moonlitmedia.co.uk"
      link={{}}
    >
      <Box textAlign="center">
        <Button
          variant="contained"
          sx={{ marginTop: 2, width: 'auto' }}
          componet="a"
          href="/"
        >
          Back to dashboard
        </Button>
      </Box>
    </Error>
  );
}

export function ViewerError() {
  return (
    <Error
      heading="You no longer have access to this page"
      message="Try accessing the content through the dashboard again"
      link={{}}
    >
      <Box textAlign="center">
        <Button
          variant="contained"
          sx={{ marginTop: 2, width: 'auto' }}
          componet="a"
          href="/"
        >
          Back to dashboard
        </Button>
      </Box>
    </Error>
  );
}

export default Error;
