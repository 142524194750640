import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button } from '@mui/material';

export function SupportEmailLink() {
  return (
    <p>
      Having trouble? Contact
      {' '}
      <ButtonMailto
        label="support@moonlitmedia.co.uk"
        mailto="mailto:support@moonlitmedia.co.uk"
      />
    </p>
  );
}

export function VerifyEmailLink() {
  return (
    <Typography sx={{ color: 'red' }}>
      Your email has not been verified contact
      {' '}
      <ButtonMailto
        label="support@moonlitmedia.co.uk"
        mailto="mailto:support@moonlitmedia.co.uk?subject=Verify Email&body=Hi, I need to verify my email"
      />
    </Typography>
  );
}

export function DeleteAccountLink() {
  return (
    <Typography sx={{ color: 'red' }}>
      You can request for your account to be deleted via email
      {' '}
      <ButtonMailto
        label="Send email"
        mailto="mailto:support@moonlitmedia.co.uk?subject=Delete Account&body=Hi, I need to delete my portal account"
      />
    </Typography>
  );
}

function ButtonMailto({ mailto, label }) {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      to="#"
      onClick={(e) => {
        window.location.href = mailto;
        e.preventDefault();
      }}
    >
      {label}
    </Link>
  );
}

export function NavButton({
  key, href, onClick, children,
}) {
  return (
    <Button
      key={key}
      href={href}
      onClick={onClick}
      variant="text"
      sx={{
        color: 'text.primary',
        '&:hover': {
          color: 'text.secondary',
        },
        marginRight: 4,
      }}
    >
      {children}
    </Button>
  );
}
