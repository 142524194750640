import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import {
  Button, Typography, Box, CircularProgress,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Analytics } from '@aws-amplify/analytics';
import SearchBar from '../components/SearchBar';
import DisplayTab from '../components/DisplayTab';
import { getAllContent } from '../requests';

function Search() {
  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState([]);
  const [queryParams] = useSearchParams();
  const isMobileWidth = useMediaQuery('(max-width:600px)');

  React.useEffect(() => {
    const searchTerm = queryParams.get('q');

    Analytics.record({
      name: 'Search',
      attributes: { searchTerm },
    });

    getAllContent()
      .then((res) => {
        if (res.length > 0) {
          if (searchTerm) {
            console.info('Running search ', searchTerm);
            const searchResults = res[0].items.filter(
              (item) => item.title.toLowerCase().includes(searchTerm.toLowerCase())
                || item.text.toLowerCase().includes(searchTerm.toLowerCase()),
            );
            setItems(searchResults);
          } else {
            window.location.href = '/';
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [queryParams]);

  return (
    <div>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          margin: 3,
          marginTop: 0,
        }}
      >
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Grid container sx={{ marginBottom: 2 }}>
            <Grid
              item
              xs={6}
              sm={3}
              sx={{ mt: isMobileWidth ? 2 : 2.5, mb: 1 }}

            >
              <Typography variant="h5">
                Search Results
              </Typography>
              <Typography variant="p">
                {`${items.length} results`}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={5} sx={{ mt: isMobileWidth ? 2 : 3, mb: isMobileWidth ? 2 : 0 }}>
              <Button
                variant="contained"
                component="a"
                href="/"
                size={isMobileWidth ? 'small' : 'medium'}
              >
                Back to dashboard
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <SearchBar />
            </Grid>
          </Grid>
        )}
      </Box>
      <DisplayTab contents={items} />
    </div>
  );
}

export default Search;
