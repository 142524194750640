import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import Chip from '@mui/material/Chip';
import ListItemIcon from '@mui/material/ListItemIcon';

import { NavButton } from '../utils/Buttons';
import config from '../configs/config';

function MenuAppBar({ isAuthenticated }) {
  const isMobileWidth = useMediaQuery('(max-width:600px)');

  const navLinks = config.navigation.main;
  const accountLinks = config.navigation.account;

  const visitHome = () => {
    window.location.href = '/';
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar sx={{ margin: 2 }}>
          <Typography
            variant={isMobileWidth ? 'h4' : 'h3'}
            component="div"
            align="left"
            sx={{
              fontFamily: '"Amatica SC", "sans-serif"', fontWeight: 'bold', flexGrow: 1, color: 'black', cursor: 'pointer',
            }}
            onClick={visitHome}
          >
            Moonlit Magic
            <Chip
              label="Early Access"
              sx={{ backgroundColor: '#D2AF26', margin: isMobileWidth ? 1 : 2 }}
              size="small"
            />
          </Typography>
          {isMobileWidth ? (
            <MLMenu
              array={isAuthenticated ? navLinks.concat(accountLinks) : navLinks}
              Icon={MenuIcon}
            />
          ) : (
            <>
              <DesktopNav array={navLinks} />
              {isAuthenticated && (
              <MLMenu
                array={accountLinks}
                Icon={AccountCircle}
                text="Account"
              />
              )}
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

function MLMenu({ array, Icon, text }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton
        size="large"
        aria-label="Menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        data-amplify-analytics-on="click"
        data-amplify-analytics-name="NavClick"
        data-amplify-analytics-attrs="button:title"
        color="inherit"
        sx={{ flexGrow: 1, color: 'black' }}
      >
        <Typography variant="h6" sx={{ marginRight: 1 }}>
          {text}
        </Typography>
        <Icon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {array.map((element) => (
          <MenuItem
            component="a"
            key={element.key}
            href={element.href}
            onClick={element.onClick}
            data-amplify-analytics-on="click"
            data-amplify-analytics-name="NavClick"
            data-amplify-analytics-attrs={`button:${element.key}`}
          >
            <ListItemIcon>
              {element.icon}
            </ListItemIcon>
            {element.key}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

function DesktopNav({ array }) {
  return (
    <div style={{ flex: 8, marginLeft: 50 }}>
      {array.map((element) => (
        <NavButton
          key={element.key}
          href={element.href}
          onClick={element.onClick}
          data-amplify-analytics-on="click"
          data-amplify-analytics-name="NavClick"
          data-amplify-analytics-attrs={`button:${element.key}`}
        >
          <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
            {element.icon}
          </ListItemIcon>
          {element.key}
        </NavButton>
      ))}
    </div>
  );
}

export default MenuAppBar;
