import { Typography, Box, Button } from '@mui/material';
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import useMediaQuery from '@mui/material/useMediaQuery';

function CheckoutConfirm() {
  const isMobileWidth = useMediaQuery('(max-width:600px)');

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Card sx={{
        display: 'flex', width: 'auto', flexWrap: 'wrap-reverse', mt: 10,
      }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto', maxWidth: isMobileWidth ? '80vw' : '30vw' }}>
            <Typography align="center" variant="h4">
              Thank you
            </Typography>
            <Typography align="center" variant="h4">
              Your subscription will be live shortly
            </Typography>
            <Typography align="center" variant="subtitle1">
              Thank you, for subscribing.
              You should be able to access all the Moonlit Magic content within 5 minutes.
              If you have any issues or feedback,
              feel free to contact us at support@moonlitmedia.co.uk
            </Typography>
            <Box textAlign="center">
              <Button variant="contained" sx={{ marginTop: 2, width: 'auto' }} componet="a" href="/">
                Back to Dashboard
              </Button>
            </Box>
          </CardContent>

        </Box>
        <CardMedia
          component="img"
          sx={{ width: isMobileWidth ? '100%' : 400 }}
          image={`/images/dog${Math.floor(Math.random() * 5) + 1}.jpg`}
          alt="Success Img"
        />
      </Card>
    </Box>

  );
}

export default CheckoutConfirm;
