/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Grid from '@mui/material/Grid';
import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { NoResultsError, FailedToLoadContent } from './Errors';
import ContentCard from './card/ContentCard';
import { contentPreview } from '../atoms';

function DisplayTab({ contents }) {
  const [previewContent, setPreviewContent] = useAtom(contentPreview);

  const setPreview = (bool, uuid) => {
    if (bool) {
      setPreviewContent(uuid);
    } else {
      setPreviewContent(RESET);
    }
  };
  if (!contents) {
    console.info('Failed to load content');
    return <FailedToLoadContent />;
  } if (contents.length === 0) {
    console.info('No results for current tab');
    return <NoResultsError />;
  }
  return (
    <Grid
      sx={{ flexGrow: 1, mb: 2 }}
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
    >
      {contents.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid item key={`content-card-item-${item.tag}-${index}`}>
          <ContentCard
            {...item}
            preview={previewContent === item.uuid}
            setOpen={(bool) => setPreview(bool, item.uuid)}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default DisplayTab;
