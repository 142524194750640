/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios';
import { Auth } from '@aws-amplify/auth';
import { Toast } from './utils/notifications.ts';
import config from './configs/config';

const apiRequest = async (method, url, params, tryNum = 1) => {
  const retryCount = 4;
  const token = await Auth.currentSession().then(
    (data) => data.idToken.jwtToken,
  );
  return new Promise((resolve, reject) => {
    axios({
      method,
      url,
      timeout: 3000,
      headers: { Authorization: `${token}` },
      data: params,
    })
      .then((jsonResponse) => {
        resolve(jsonResponse.data);
      })
      .catch(async (error) => {
        if (error.response) {
          if (error.response) {
            console.log(error);
            console.log('Error', error.response.data.message, 'danger');
          } else if (error.request) {
            Toast('Error', "Can't connect to the server", 'danger');
          } else {
            Toast('Error', error.message, 'danger');
          }
          reject({ ...error });
        } else if (tryNum < retryCount) {
          Toast(
            'Error',
            `Can't connect - Retrying request ${tryNum}/${retryCount} waiting ${
              2 ** tryNum
            } seconds`,
            'danger',
          );
          setTimeout(async () => {
            await apiRequest(method, url, params, tryNum + 1)
              .then((data) => resolve(data))
              .catch((nerror) => reject(nerror));
          }, 2 ** tryNum * 1000);
        } else {
          Toast('Error', 'Unable to connect to server', 'danger');
          reject({ ...error });
        }
      });
  });
};

export const getAllContent = async () => {
  const url = `${config.contentBucketUrl}/content.json`;
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url,
      timeout: 3000,
    })
      .then((jsonResponse) => {
        resolve(jsonResponse.data);
      })
      .catch(async (error) => {
        reject({ ...error });
      });
  });
};

export const getBillingPortal = async () => {
  const url = `${config.apiBaseUrl}/billing`;
  const method = 'post';
  const response = await apiRequest(method, url, {});
  return response;
};

export const getCheckoutPortal = async () => {
  const url = `${config.apiBaseUrl}/checkout`;
  const method = 'post';
  const response = await apiRequest(method, url, {});
  return response;
};

export const getContentDetails = async (uuid, fileType) => {
  const url = `${config.apiBaseUrl}/content-details?uuid=${uuid}&fileType=${fileType}`;
  const method = 'get';
  const response = await apiRequest(method, url, { });
  return response;
};

export const checkIsSubscribed = async () => {
  const url = `${config.apiBaseUrl}/subscribed`;
  const method = 'get';
  const response = await apiRequest(method, url, { });
  return response;
};
