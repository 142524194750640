import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSearchParams } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

function SearchBar() {
  const isMobileWidth = useMediaQuery('(max-width:600px)');
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get('q') || '';
  const [text, setText] = React.useState(searchTerm);

  const searchHandle = (e) => {
    if (e.key === 'Enter' || e.type === 'click') {
      if (text.length > 0) {
        e.preventDefault();
        window.location.href = `/search?q=${text}`;
      }
    }
  };

  const clearSearch = () => setText('');

  return (
    <TextField
      id="search-text-field"
      label="Search"
      sx={{ marginTop: isMobileWidth ? 0 : 2.5 }}
      fullWidth
      value={text}
      onKeyPress={searchHandle}
      onChange={(e) => setText(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <Tooltip title="Search">
              <IconButton
                disabled={!text.length > 0}
                aria-label="Search"
                onClick={(e) => searchHandle(e)}
              >
                <SearchIcon />
              </IconButton>
            </Tooltip>
            {text.length > 0 && (
              <Tooltip title="Clear">
                <IconButton aria-label="Clear" onClick={clearSearch}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
}

export default SearchBar;
