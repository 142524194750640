import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import useMediaQuery from '@mui/material/useMediaQuery';
import SearchBar from './SearchBar';

function Toolbar({ currentTabIndex, tabs, setCurrentTab }) {
  const isMobileWidth = useMediaQuery('(max-width:600px)');

  const setTab = (value) => {
    const tab = tabs[value];
    tab.index = value;
    tab.items = tab.items || tabs[0].items.filter((item) => item.tag === tab.tag);
    setCurrentTab(tabs[value]);
  };

  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        margin: 3,
        marginTop: 0,
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={9}>
          <Tabs
            sx={isMobileWidth ? { mt: 1, mb: 1 } : { m: 3 }}
            value={currentTabIndex}
            onChange={(e, index) => setTab(index)}
            aria-label="ML Tabs"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            orientation={isMobileWidth ? 'horizontal' : 'horizontal'}
          >
            {tabs.map((tab, index) => (
              <Tab
                // eslint-disable-next-line react/no-array-index-key
                key={`tab-${index}`}
                label={tab.label}
                {...{
                  id: `tab-${index}`,
                  'aria-controls': `tabpanel-${index}`,
                }}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={12} sm={3}>
          <SearchBar />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Toolbar;
