import * as React from 'react';

function ErrorFallback({ error }) {
  const reportError = () => {
    const email = 'mailto:support@moonlitmedia.co.uk?subject=Portal unexpected error&body=';
    const location = JSON.stringify(window.location);
    const errorDetails = `${error.message}${error.stack}${location}`;
    const message = `I ran into a unexpected error here are the details: ${errorDetails}`;

    window.location = `${email}${message}`;
  };

  return (
    <div role="alert" style={{ textAlign: 'center' }}>
      <p>Something went wrong</p>
      <p>We ran into a error that we havent seen before</p>
      <p>Please report this error to help us fix the issue</p>
      <button
        type="button"
        onClick={reportError}
      >
        Report Error
      </button>
      <pre style={{ color: 'red' }}>{error.message}</pre>
      <pre style={{ color: 'red' }}>{error.stack}</pre>
      <pre style={{ color: 'red' }}>{JSON.stringify(window.location)}</pre>
    </div>
  );
}

export default ErrorFallback;
