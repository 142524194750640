import React from 'react';
import { Auth } from '@aws-amplify/auth';
import {
  Button, Typography, Stack, TextField,
  Tooltip, Box, Divider,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Toast } from '../utils/notifications.ts';
import { FormCard } from '../components/Styled';
import { VerifyEmailLink, DeleteAccountLink } from '../utils/Buttons';

function AttributeTextField({
  name, value, onChange, disabled = false,
}) {
  const copyToClipboard = (text) => {
    try {
      navigator.clipboard.writeText(text);
      Toast('Success', 'Copied to clipboard', 'success');
    } catch (err) {
      Toast('Error', 'Unable to copy to clipboard', 'danger');
    }
  };

  return (
    <TextField
      key={name}
      label={name}
      variant="outlined"
      fullWidth
      value={value}
      onChange={onChange}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <Tooltip title="Copy">
              <IconButton
                aria-label="Copy"
                onClick={() => copyToClipboard(value)}
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
}

function Account() {
  const [user, setUser] = React.useState();
  const [accountAttributes, setAccountAttributes] = React.useState();
  const [saveDisabled, setSaveDisabled] = React.useState(true);

  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        setUser(userData);
        setAccountAttributes({
          email: userData.attributes.email,
          name: userData.attributes.name,
        });
      })
      .catch(() => {
        Toast('Error', 'Unable to get user data', 'danger');
      });
  }, []);

  const copyAdvanced = () => {
    try {
      if (
        // eslint-disable-next-line no-alert
        window.confirm(
          'SENSITIVE INFORMATION - Do not share this information with anyone accept the moonlit media team',
        )
      ) {
        navigator.clipboard.writeText(JSON.stringify(user));
        Toast('Success', 'Copied to clipboard', 'success');
      }
    } catch (err) {
      Toast('Error', 'Unable to copy to clipboard', 'danger');
    }
  };

  const updateAttributes = (e, key) => {
    setAccountAttributes({
      ...accountAttributes,
      [key]: e.target.value,
    });
    setSaveDisabled(false);
  };

  const saveAttributes = () => {
    Auth.updateUserAttributes(user, accountAttributes)
      .then(() => {
        Toast('Success', 'Account information updated', 'success');
      })
      .catch((err) => {
        Toast('Error', err.message, 'danger');
      });
  };
  if (!user) {
    return <p>Loading</p>;
  }
  return (
    <div>
      <FormCard>
        <Stack spacing={4}>
          <Typography variant="h4">Account Info</Typography>
          <Divider sx={{ m: 2 }} />
          {Object.keys(accountAttributes).map((key) => (
            <AttributeTextField
              key={key}
              name={key}
              disabled={key === 'email'}
              value={accountAttributes[key]}
              onChange={(e) => updateAttributes(e, key)}
            />
          ))}
          {!user.attributes.email_verified && <VerifyEmailLink />}
          <Stack spacing={4} direction="row">
            <Button
              variant="contained"
              sx={{ width: 150 }}
              onClick={saveAttributes}
              disabled={saveDisabled}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              sx={{ width: 200 }}
              component="a"
              href="/billing"
            >
              Edit Billing Info
            </Button>
            <Button
              variant="outlined"
              sx={{ width: 200 }}
              component="a"
              href="/change-password"
            >
              Change Password
            </Button>
          </Stack>
          <DeleteAccountLink />
        </Stack>
      </FormCard>
      <FormCard>
        <Stack direction="row" spacing={4}>
          <Typography variant="h4">Advanced - Private</Typography>
          <Button variant="outlined" onClick={copyAdvanced}>
            Copy to clipboard
          </Button>
        </Stack>
        <Box>
          <Divider sx={{ m: 1 }} />
          <Stack spacing={2}>
            {Object.keys(user.attributes).map((key) => (
              <Typography>{`${key}: ${user.attributes[key]}`}</Typography>
            ))}
            <AttributeTextField
              key="Access Token"
              name="Access Token"
              disabled
              value={user.signInUserSession.accessToken.jwtToken}
            />
            <AttributeTextField
              key="ID Token"
              name="ID Token"
              disabled
              value={user.signInUserSession.idToken.jwtToken}
            />
          </Stack>

        </Box>
      </FormCard>
    </div>
  );
}

export default Account;
