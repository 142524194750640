import React from 'react';
import {
  Dialog,
  Slide,
  Typography,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from 'file-saver';
import base64 from 'base-64';
import { Analytics } from '@aws-amplify/analytics';
import CircularProgress from '@mui/material/CircularProgress';
import { stringToColour } from '../../utils/strings';
import { getContentDetails } from '../../requests';
import { Toast } from '../../utils/notifications.ts';

// eslint-disable-next-line react/jsx-props-no-spreading
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function CardPopup({
  open, closeViewer, title, text, tag, date, img, uuid, fileType,

}) {
  const isMobileWidth = useMediaQuery('(max-width:600px)');
  const [url, setUrl] = React.useState('');

  const getUrlExtension = () => url.split(/[#?]/)[0].split('.').pop().trim();

  React.useEffect(() => {
    if (open) {
      Analytics.record({
        name: 'PreviewContent',
        attributes: { uuid },
      });
      console.log('Load URL');
      getContentDetails(uuid, fileType)
        .then((res) => {
          setUrl(res.url);
        })
        .catch((error) => {
          Toast('Error', 'Cannot get additional details', 'danger');
          console.log(error);
        });
    }
  }, [open]);

  const viewFile = () => {
    window.location.href = `viewer?url=${base64.encode(url)}`;
  };

  const saveFile = () => {
    const fileName = `${title}.${getUrlExtension()}`;
    saveAs(
      url,
      fileName,
    );
  };

  return (
    <Dialog
      fullScreen={isMobileWidth}
      open={open}
      onClose={() => closeViewer()}
      TransitionComponent={Transition}
    >
      <Card sx={{
        maxWidth: isMobileWidth ? window.width : '80vw', height: window.height, overflow: 'auto', border: 'none', boxShadow: 'none',
      }}
      >
        <CardHeader
          avatar={(
            <Chip
              label={tag.charAt(0).toUpperCase() + tag.slice(1)}
              color="primary"
              style={{ backgroundColor: stringToColour(tag) }}
            />
          )}
          action={(
            <IconButton aria-label="Close" onClick={() => closeViewer()}>
              <CloseIcon sx={{ margin: 1.5 }} />
            </IconButton>
)}
          title={title}
          subheader={date}
        />
        <CardMedia component="img" image={img} alt={title} />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {text}
          </Typography>

        </CardContent>
      </Card>
      <BottomNavigation
        showLabels
      >
        {!url && <CircularProgress />}
        {url && fileType !== 'html'
        && (
        <BottomNavigationAction
          label="View"
          icon={<FileOpenIcon />}
          onClick={() => viewFile()}
          data-amplify-analytics-on="click"
          data-amplify-analytics-name="ContentActionClick"
          data-amplify-analytics-attrs={`button:View,uuid:${uuid}`}
        />
        )}
        {url && (
        <BottomNavigationAction
          label="Download"
          icon={<DownloadIcon />}
          onClick={() => saveFile()}
          data-amplify-analytics-on="click"
          data-amplify-analytics-name="ContentActionClick"
          data-amplify-analytics-attrs={`button:Download,uuid:${uuid}`}
        />
        ) }
      </BottomNavigation>
    </Dialog>
  );
}
export default CardPopup;
