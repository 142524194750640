/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Auth } from '@aws-amplify/auth';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useSearchParams } from 'react-router-dom';
import base64 from 'base-64';
import { Toast } from '../../utils/notifications.ts';
import { useInput } from '../../utils/forms';
import { SupportEmailLink } from '../../utils/Buttons';
import { TextLink, FormField } from '../Styled';

function Login() {
  const [loading, setLoading] = React.useState(false);
  const [queryParams] = useSearchParams();

  const getEmailQueryParam = () => {
    try {
      return base64.decode(queryParams.get('email') || '') || '';
    } catch (error) {
      return '';
    }
  };

  const { value: email, bind: bindEmail } = useInput(getEmailQueryParam());
  const { value: password, bind: bindPassword } = useInput('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const user = await Auth.signIn(email, password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await Auth.completeNewPassword(user, password);
      }
      Toast('Success', 'Login Successful', 'success');
      window.location.reload();
    } catch (error) {
      Toast('Error', error.message, 'danger');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      onSubmit={handleSubmit}
    >
      <h1 style={{ fontSize: '22px', fontWeight: 800 }}>
        Login to an existing account
      </h1>

      <FormField label="Email" {...bindEmail} type="email" />
      <FormField label="Password" type="password" {...bindPassword} />
      <Button
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        disabled={loading}
      >
        {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
        Login to Your Account
      </Button>
      <SupportEmailLink />
      <TextLink to="/forgot-password">Forgotton Password? </TextLink>
      <TextLink to="/register">Create an account &rarr;</TextLink>
    </form>
  );
}

export default Login;
