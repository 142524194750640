import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';
import Toolbar from '../components/Toolbar';
import DisplayTab from '../components/DisplayTab';
import { getAllContent, getCheckoutPortal, checkIsSubscribed } from '../requests';
import { NotSubscribedError } from '../components/Errors';

function Dashboard() {
  const [loading, setLoading] = React.useState(true);
  const [tabs, setTabs] = React.useState([]);
  const [currentTab, setCurrentTab] = React.useState({});
  const [checkoutUrl, setCheckoutUrl] = React.useState('');

  const setResponse = (res) => {
    if (res?.length > 0) {
      setTabs(res);
      setCurrentTab(res[0]);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    checkIsSubscribed()
      .then(() => {
        getAllContent()
          .then(setResponse)
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
      })
      .catch(() => {
        getCheckoutPortal()
          .then((res) => {
            setCheckoutUrl(res.url);
            setLoading(false);
          })
          .catch((nerror) => {
            setLoading(false);
            console.log(nerror);
          });
      });
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }
  return (
    <Box sx={{ width: '100%' }}>
      {checkoutUrl === '' ? (
        <Box>
          <Toolbar
            currentTabIndex={currentTab.index}
            tabs={tabs}
            setCurrentTab={setCurrentTab}
          />
          <DisplayTab contents={currentTab.items} />
        </Box>
      ) : (
        <Box sx={{ margin: 5 }}>
          <NotSubscribedError url={checkoutUrl} />
        </Box>
      )}
    </Box>
  );
}

export default Dashboard;
