import { TextField, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

export const FormField = styled(TextField)({
  margin: '10px 0',
});

export const TextLink = styled(Link)({
  margin: '15px 0',
  textAlign: 'center',
});

export const FormCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ isMobileWidth = useMediaQuery('(max-width:600px)') }) => ({
  width: isMobileWidth ? 'calc(100vw - 40px)' : 1000,
  margin: isMobileWidth ? '20px auto' : '100px auto',
  padding: isMobileWidth ? '20px' : '40px',
}));
