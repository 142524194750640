import { Store } from 'react-notifications-component';
// eslint-disable-next-line import/prefer-default-export
export const Toast = (
  title: string,
  message: string,
  type: 'success' | 'danger' | 'info' | 'default' | 'warning',
) => {
  Store.addNotification({
    title,
    message,
    type,
    insert: 'top',
    container: 'bottom-left',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 4000,
      onScreen: true,
    },
  });
};
