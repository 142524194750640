import React from 'react';
import { FormCard } from '../components/Styled';
import Login from '../components/authentication/Login';
import Register from '../components/authentication/Register';
import ForgottonPassword from '../components/authentication/ForgottonPassword';

export function LoginPage() {
  return (
    <FormCard>
      <Login />
    </FormCard>
  );
}

export function RegisterPage() {
  return (
    <FormCard>
      <Register />
    </FormCard>
  );
}

export function ForgottonPasswordPage({ email = '', title = 'Forgotton Password' }) {
  return (
    <FormCard>
      <ForgottonPassword defaultEmail={email} title={title} />
    </FormCard>
  );
}
