const stringToColourRandom = (str) => {
  const array = [...str];
  // eslint-disable-next-line no-bitwise
  const stringUniqueHash = array.reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
  return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
};

// eslint-disable-next-line import/prefer-default-export
export const stringToColour = (str) => {
  switch (str) {
    case 'templates':
      return '#9C6644';
    case 'ideas':
      return '#7F5539';
    case 'training':
      return '#B08968';
    case 'audio':
      return '#DDB892';
    case 'workbooks':
      return '#E6CCB2';
    default:
      return stringToColourRandom(str);
  }
};
