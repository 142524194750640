/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Auth } from '@aws-amplify/auth';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Toast } from '../../utils/notifications.ts';
import { useInput } from '../../utils/forms';
import { SupportEmailLink } from '../../utils/Buttons';
import { TextLink, FormField } from '../Styled';

function Register() {
  const [loading, setLoading] = React.useState(false);
  const [confirm, setConfirm] = React.useState(false);

  const { value: name, bind: bindName } = useInput('');
  const { value: email, bind: bindEmail } = useInput('');
  const { value: password, bind: bindPassword } = useInput('');
  const { value: confirmPassword, bind: bindConfirmPassword } = useInput('');

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (password !== confirmPassword) {
      Toast('Error', 'Password and Confirm Password should be same', 'danger');
      setLoading(false);
      return;
    }
    try {
      await Auth.signUp({
        username: email,
        password: confirmPassword,
        attributes: {
          email,
          name,
        },
      });
      Toast('Success', 'A verification email has been sent', 'success');
      setConfirm(true);
    } catch (error) {
      console.error(error);
      Toast('Error', error.message, 'danger');
    } finally {
      setLoading(false);
    }
  };

  if (confirm) {
    return <Confirmation email={email} password={confirmPassword} />;
  }

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      onSubmit={handleSignUp}
    >
      <h1 style={{ fontSize: '22px', fontWeight: 800 }}>
        New Account Registration
      </h1>
      <FormField label="Name" {...bindName} />
      <FormField label="Email" {...bindEmail} type="email" />
      <FormField label="Password" type="password" {...bindPassword} />
      <span>
        Password must be atleast 8 characters and contain atleast 1 number, 1
        special character, 1 uppercase and 1 lowercase
      </span>
      <FormField
        label="Confirm Password"
        type="password"
        {...bindConfirmPassword}
      />

      <span>
        By registering you agree to our
        <a target="_blank" href="https://moonlitmedia.co.uk/moonlit-magic-terms-and-conditions/" rel="noreferrer">
          {' '}
          terms and conditions
        </a>
      </span>
      <Button
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        disabled={loading}
        sx={{ marginTop: 2 }}
      >
        {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
        Sign Up
      </Button>
      <SupportEmailLink />
      <TextLink to="/signin">Login to your account &rarr;</TextLink>
    </form>
  );
}

function Confirmation({ email, password }) {
  const [loading, setLoading] = React.useState(false);
  const { value: code, bind: bindCode } = useInput('');

  const reSendConfirmation = async () => {
    setLoading(true);
    try {
      await Auth.resendSignUp(email);
      Toast('Success', 'Another code has been sent to your email', 'success');
    } catch (error) {
      Toast('Error', error.message, 'danger');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await Auth.confirmSignUp(email, code);
      await Auth.signIn(email, password);
      Toast('Success', 'Verified Successfully', 'success');
      window.location.href = '/';
    } catch (error) {
      Toast('Error', error.message, 'danger');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      onSubmit={handleSubmit}
    >
      <h1 style={{ fontSize: '22px', fontWeight: 800 }}>Verify Your Account</h1>
      <span>
        {`We have sent a verification code to ${email}`}
      </span>
      <FormField label="Verification Code" {...bindCode} />
      <Button
        variant="outlined"
        color="primary"
        size="large"
        disabled={loading}
        onClick={() => reSendConfirmation()}
        sx={{
          mt: 2,
        }}
      >
        {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
        Resend verification code
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        disabled={loading}
        sx={{ marginTop: 1 }}
      >
        {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
        Submit
      </Button>
      <SupportEmailLink />
    </form>
  );
}

export default Register;
