import * as React from 'react';
import {
  Paper, Stack, Box, Typography, IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import config from '../configs/config';
import packageJson from '../../package.json';

function MenuAppBar() {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        bottom: 0,
        width: '100%',
        background: theme.palette.primary.main,
        pt: 1,
        pb: 1,
      }}
      component="footer"
      square
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          m: 2,
        }}

      >
        <Typography variant="caption">
          {`Copyright ©2022. Moonlit Media. ${packageJson.version}`}
        </Typography>
        <Stack direction="row" spacing={1}>
          {config.socialLinks.map((item) => (
            <IconButton
              component="a"
              aria-label={`Moonlit Media ${item.name}`}
              href={item.href}
              data-amplify-analytics-on="click"
              data-amplify-analytics-name="SocialClick"
              data-amplify-analytics-attrs={`button:${item.name}`}
            >
              {item.icon}
            </IconButton>
          ))}
        </Stack>
      </Box>
    </Paper>
  );
}

export default MenuAppBar;
