import React from 'react';
import {
  Facebook, Instagram, Web, LinkedIn, Pinterest,
} from '@mui/icons-material';
import { Auth } from '@aws-amplify/auth';
import SettingsIcon from '@mui/icons-material/Settings';
import PaymentIcon from '@mui/icons-material/Payment';
import LogoutIcon from '@mui/icons-material/Logout';
import GridViewIcon from '@mui/icons-material/GridView';
import EmailIcon from '@mui/icons-material/Email';
import awsenv from './awsenv.json';

const generateConfig = () => Object.assign(
  {},
  ...awsenv.map((s) => ({ [s.Name]: s.Value })),
);

const aws = generateConfig();

const logOut = () => {
  (async () => {
    try {
      await Auth.signOut();
    } catch (err) {
      console.error(`Logout Error: ${err}`);
    }
    window.location.href = '/';
  })();
};

const main = [
  { key: 'Dashboard', href: '/', icon: <GridViewIcon fontSize="small" /> },
  {
    key: 'Main Site',
    onClick: () => window.open('https://moonlitmedia.co.uk', '_blank'),
    icon: <Web fontSize="small" />,
  },
  {
    key: 'Contact Us',
    onClick: () => window.open('https://moonlitmedia.co.uk/contact/', '_blank'),
    icon: <EmailIcon fontSize="small" />,
  },
];

const account = [
  { key: 'Settings', href: '/account', icon: <SettingsIcon fontSize="small" /> },
  { key: 'Billing', href: '/billing', icon: <PaymentIcon fontSize="small" /> },
  {
    key: 'Log out',
    onClick: () => logOut(),
    icon: <LogoutIcon fontSize="small" />,
  },
];

const socialLinks = [
  { name: 'Facebook', href: 'https://m.facebook.com/Moonlitmediamarketing/', icon: <Facebook /> },
  { name: 'Instagram', href: 'https://www.instagram.com/moonlitmedia_/', icon: <Instagram /> },
  { name: 'Website', href: 'https://moonlitmedia.co.uk/', icon: <Web /> },
  { name: 'LinkedIn', href: 'https://www.linkedin.com/in/shannen-davies-42a63b192/', icon: <LinkedIn /> },
  { name: 'Pinterest', href: 'https://www.pinterest.co.uk/MoonlitMedia_/', icon: <Pinterest /> },
];

const contentBucketUrl = `https://${aws['CONTENT-S3-BUCKET']}.s3.eu-west-1.amazonaws.com`;
const config = {
  socialLinks,
  navigation: {
    main, account,
  },
  apiBaseUrl: aws['API-ENDPOINT'],
  contentBucketUrl,
  thumbnailsBaseUrl: `${contentBucketUrl}/images/thumbnails`,
  aws,
};
export default config;
