import * as React from 'react';
import Chip from '@mui/material/Chip';
import LaunchIcon from '@mui/icons-material/Launch';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { stringToColour } from '../../utils/strings';
import CardPopup from './CardPopup';

import config from '../../configs/config';

function ContentCard({
  title, text, tag, date, uuid, fileType, preview, setOpen,
}) {
  const isMobileWidth = useMediaQuery('(max-width:600px)');

  const openPopup = () => {
    // window.history.replaceState(null, title, `/content/${uuid}`);

    setOpen(true);
  };
  return (
    <Card square sx={{ maxWidth: isMobileWidth ? window.width : 345 }} raised={!isMobileWidth}>
      <CardActionArea onClick={() => openPopup()}>
        <CardHeader
          sx={{ height: 50 }}
          avatar={(
            <Chip
              label={tag.charAt(0).toUpperCase() + tag.slice(1)}
              color="primary"
              style={{ backgroundColor: stringToColour(tag) }}
            />
          )}
          action={<LaunchIcon sx={{ margin: 1.5 }} />}
          title={title}
          subheader={date}
        />
        <CardMedia component="img" height="194" image={`${config.thumbnailsBaseUrl}/${uuid}.png`} alt={title} />
        <CardContent sx={{ height: 100 }}>
          <Typography variant="body2" color="text.secondary">
            {text.length > 250 ? `${text.substring(0, 250)} ...` : text}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardPopup
        open={preview}
        closeViewer={() => setOpen(false)}
        title={title}
        img={`${config.thumbnailsBaseUrl}/${uuid}.png`}
        text={text}
        tag={tag}
        date={date}
        uuid={uuid}
        fileType={fileType}
      />
    </Card>
  );
}
export default ContentCard;
