import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  Box, Button, Backdrop,
} from '@mui/material';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import CircularProgress from '@mui/material/CircularProgress';
import base64 from 'base-64';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Plyr from 'plyr-react';
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from 'file-saver';
import { ViewerError } from '../components/Errors';
import 'plyr-react/plyr.css';
import '../styles/viewer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

function Error() {
  return (
    <Box sx={{ width: '100%', m: 2, marginTop: 10 }}>
      <ViewerError />
    </Box>
  );
}

function Loading() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <CircularProgress color="primary" />
    </Box>
  );
}

function VideoPlayer({ url }) {
  return (
    <Plyr source={{
      type: 'video',
      sources: [
        {
          src: url,
        },
      ],
    }}
    />
  );
}

function PDFPageView({ index }) {
  const [loading, setLoading] = React.useState(true);
  return (
    <Page
      className="PDFPage"
      key={`page_${index + 1}`}
      pageNumber={index + 1}
      renderForms
      onRenderSuccess={() => setLoading(false)}
    >
      <Backdrop
        open={loading}
        sx={{ position: 'absolute' }}
      >
        <CircularProgress color="primary" disableShrink />
      </Backdrop>
    </Page>
  );
}
function PDFViewer({ url }) {
  const [numPages, setNumPages] = React.useState(null);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  return (
    <Box>
      <Document
        file={{
          url,
        }}
      // eslint-disable-next-line react/jsx-no-bind
        onLoadSuccess={onDocumentLoadSuccess}
        error={<Error />}
        loading={<Loading />}
        className="PDFDocument"
      >
        {Array.from(new Array(numPages), (el, index) => (
          <PDFPageView index={index} />
        ))}
      </Document>
    </Box>
  );
}

function ViewFile({ url, fileType }) {
  switch (fileType) {
    case 'pdf':
      return <PDFViewer url={url} />;
    case 'mp4':
      return <VideoPlayer url={url} />;
    default:
      return <p>Viewing Not supported on browser. Please Download instead</p>;
  }
}

function Viewer() {
  const [queryParams] = useSearchParams();
  const [url, setUrl] = React.useState('');
  const [fileType, setFileType] = React.useState('');

  const navigate = useNavigate();

  const saveFile = () => {
    const fileName = url.split('/').pop().split('#')[0].split('?')[0];
    saveAs(
      url,
      fileName,
    );
  };

  React.useEffect(() => {
    console.info('Loading Document');

    try {
      const rawUrl = queryParams.get('url') || '';
      const decodedUrl = base64.decode(rawUrl) || '';
      const urlFileType = decodedUrl.split(/[#?]/)[0].split('.').pop().trim();
      setFileType(urlFileType);
      setUrl(decodedUrl);
    } catch (error) {
      setUrl('');
    }
  }, [queryParams]);

  if (url !== '') {
    return (
      <Box sx={{ mt: 2, mb: 2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            m: 1,
          }}
        >
          <Button
            sx={{ ml: 2 }}
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
          >
            Back
          </Button>
          <Button
            sx={{ mr: 2 }}
            onClick={() => saveFile()}
            startIcon={<DownloadIcon />}
          >
            Download
          </Button>
        </Box>
        <div id="ResumeContainer">
          <ViewFile url={url} fileType={fileType} />
        </div>
      </Box>
    );
  }
  return <p>Incorrect URL Parameter</p>;
}

export default Viewer;
