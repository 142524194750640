import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#AE8625',
    },
    secondary: {
      main: '#f78da7',
    },
  },
  typography: {
    fontFamily: '"Titillium Web", "sans-serif"',
  },
});

export default responsiveFontSizes(theme);
