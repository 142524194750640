/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Auth } from '@aws-amplify/auth';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Toast } from '../../utils/notifications.ts';
import { useInput } from '../../utils/forms';
import { SupportEmailLink } from '../../utils/Buttons';
import { FormField } from '../Styled';

function ForgottonPassword({ defaultEmail, title }) {
  const [loading, setLoading] = React.useState(false);
  const [confirm, setConfirm] = React.useState(false);

  const { value: email, bind: bindEmail } = useInput(defaultEmail);
  const { value: password, bind: bindPassword } = useInput('');
  const { value: confirmPassword, bind: bindConfirmPassword } = useInput('');

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (password !== confirmPassword) {
      Toast('Error', 'Password and Confirm Password should be same', 'danger');
      setLoading(false);
      return;
    }
    try {
      await Auth.forgotPassword(email)
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      Toast('Success', 'A code was sent to your email', 'success');
      setConfirm(true);
    } catch (error) {
      console.error(error);
      Toast('Error', error.message, 'danger');
    } finally {
      setLoading(false);
    }
  };

  if (confirm) {
    return <Confirmation email={email} password={password} />;
  }

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      onSubmit={handleSignUp}
    >
      <h1 style={{ fontSize: '22px', fontWeight: 800 }}>{title}</h1>
      {defaultEmail.length === 0 && <FormField label="Email" {...bindEmail} type="email" />}
      <FormField label="New Password" type="password" {...bindPassword} />
      <FormField
        label="Confirm New Password"
        type="password"
        {...bindConfirmPassword}
      />
      <span>
        Password must be atleast 8 characters and contain atleast 1 number, 1
        special character, 1 uppercase and 1 lowercase
      </span>
      <Button
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        disabled={loading}
        sx={{ marginTop: 2 }}
      >
        {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
        Change password
      </Button>
      <SupportEmailLink />
    </form>
  );
}

function Confirmation({ email, password }) {
  const [loading, setLoading] = React.useState(false);

  const { value: code, bind: bindCode } = useInput('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      Toast('Success', 'Verified Successfully', 'success');
      window.location.href = '/';
    } catch (error) {
      Toast('Error', error.message, 'danger');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      onSubmit={handleSubmit}
    >
      <h1 style={{ fontSize: '22px', fontWeight: 800 }}>
        Verify Your Password Change
      </h1>
      <FormField label="Verification Code" {...bindCode} />
      <Button
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        disabled={loading}
        sx={{ marginTop: 1 }}
      >
        {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
        Confirm password change
      </Button>
      <SupportEmailLink />
    </form>
  );
}

export default ForgottonPassword;
